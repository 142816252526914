import React,{useState, useEffect} from 'react'
import { PopupWidget,InlineWidget } from "react-calendly";
import './style.scss';

const Meeting = () => {
  const [isMobile, setMobile] = useState({width:'100vw',height:'70%'});
  useEffect(() => {
    if (window.innerWidth < 576) setMobile({width:'100vw',height:'100%'});
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile({width:'100vw',height:'100%'}) : setMobile({width:'100vw',height:'70%'});
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);
  
  return (
  <div className='calendly-container'>
     <InlineWidget url="https://calendly.com/a-team-c11/av?text_color=004e93" styles={isMobile}/>
   
  </div>
  );
}

export default Meeting;